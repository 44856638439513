import { faCaretDown, faCaretUp } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './TableSortIcon.module.scss';

export const TableSortIcon = ({ isSelected, isAsc }: { isSelected: boolean; isAsc: boolean }) => (
  <FontAwesomeIcon
    icon={isAsc ? faCaretUp : faCaretDown}
    width={8}
    className={isSelected ? styles.selected : styles.unselected}
  />
);
