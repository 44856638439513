import clsx from 'clsx';
import { Tooltip } from 'moralis-ui';
import { formatCurrency } from '@/utils/fmt';
import styles from './styles.module.scss';

interface ITokenMetricProps {
  affix?: string;
  className?: string;
  decimals?: number;
  currency?: boolean;
  hasChangeIndication?: boolean;
  hasTooltip?: boolean;
  prefix?: string;
  value: string | number;
}

interface IConvertedValueProps {
  fullValue: boolean;
}

const TokenMetric = ({
  hasChangeIndication = false,
  value,
  prefix = '',
  affix = '',
  currency = false,
  decimals = 0,
  hasTooltip,
  className,
}: ITokenMetricProps) => {
  const isPositiveChange = Math.sign(Number(value)) > 0;
  const convertedValue = (convertedValueProps?: IConvertedValueProps) => {
    if (typeof value === 'string') {
      let formattedValue: string = value;
      let fullFormattedValue: string = value;
      if (currency) {
        formattedValue = formatCurrency(Number(value.trim()));
      } else {
        formattedValue = String(Number(value).toFixed(decimals)).trim();
      }
      fullFormattedValue = value.trim();

      if (value[0] === '-' || value[0] === '+') {
        if (hasChangeIndication) {
          return convertedValueProps?.fullValue
            ? `${isPositiveChange ? '+' : Number(value) !== 0 ? '-' : ''}${prefix}${
                currency ? '$' : ''
              }${fullFormattedValue.slice(1)}${affix}`
            : `${isPositiveChange ? '+' : Number(value) !== 0 ? '-' : ''}${prefix}${formattedValue.slice(1)}${affix}`;
        } else {
          return convertedValueProps?.fullValue
            ? `${prefix}${currency ? '$' : ''}${fullFormattedValue.slice(1)}${affix}`
            : `${isPositiveChange ? '+' : Number(value) !== 0 ? '-' : ''}${prefix}${formattedValue.slice(1)}${affix}`;
        }
      } else {
        if (hasChangeIndication) {
          return `${isPositiveChange ? '+' : Number(value) !== 0 ? '-' : ''}${prefix}${formattedValue}${affix}`;
        } else {
          return `${prefix}${formattedValue}${affix}`;
        }
      }
    } else {
      let formattedValue: number | string = value;
      let fullFormattedValue: number | string = value;

      if (formatCurrency) {
        formattedValue = String(formatCurrency(value));
      } else {
        formattedValue = String(value?.toFixed(decimals));
      }
      fullFormattedValue = String(value);
      if (value <= 0) {
        return convertedValueProps?.fullValue
          ? `${value !== 0 ? '-' : ''}${prefix}${currency ? '$' : ''}${fullFormattedValue.slice(1)}${affix}`
          : `${value !== 0 ? '-' : ''}${prefix}${
              value === 0 ? formattedValue.toString() : formattedValue.toString().slice(1)
            }${affix}`;
      } else {
        if (hasChangeIndication) {
          return convertedValueProps?.fullValue
            ? `+${prefix}${currency ? '$' : ''}${fullFormattedValue}${affix}`
            : `+${prefix}${formattedValue.toString()}${affix}`;
        } else {
          return convertedValueProps?.fullValue
            ? `${prefix}${currency ? '$' : ''}${fullFormattedValue}${affix}`
            : `${prefix}${formattedValue.toString()}${affix}`;
        }
      }
    }
  };

  return (
    <div className={clsx(styles.tokenMetric, className)}>
      {(value !== undefined && hasTooltip) || (currency && hasTooltip !== false && value !== undefined) ? (
        <Tooltip
          color="navy"
          content={convertedValue({ fullValue: true })}
          trigger={
            <div
              className={clsx({
                [styles.positive]: hasChangeIndication && isPositiveChange,
                [styles.negative]: hasChangeIndication && !isPositiveChange,
                [styles.equal]: !hasChangeIndication,
              })}
            >
              {value !== undefined ? convertedValue() : 'N/A'}
            </div>
          }
        />
      ) : (
        <div
          className={clsx({
            [styles.positive]: hasChangeIndication && isPositiveChange,
            [styles.negative]: hasChangeIndication && !isPositiveChange,
            [styles.equal]: !hasChangeIndication,
          })}
        >
          {value !== undefined ? convertedValue() : 'N/A'}
        </div>
      )}
    </div>
  );
};

export default TokenMetric;
