import clsx from 'clsx';
import { Pagination, SwiperOptions } from 'swiper';
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react';
import styles from './IBSwiper.module.scss';
import 'swiper/css';
import 'swiper/css/pagination';

export const IBSwiperWrap = ({
  autoHeight = false,
  breakpoints,
  initialSlide = 1,
  slidesPerView = 4,
  children,
  customBodyClass,
  customPaginationClass,
  onSwiper,
  id,
}: {
  autoHeight?: boolean;
  breakpoints?: SwiperOptions['breakpoints'];
  initialSlide: number;
  slidesPerView: number | 'auto';
  children: React.ReactNode;
  customBodyClass?: string;
  customPaginationClass?: string;
  onSwiper?: (swiper: SwiperClass) => void;
  id: string;
}) => (
  <>
    <Swiper
      onSwiper={onSwiper}
      breakpoints={breakpoints}
      className={customBodyClass}
      initialSlide={initialSlide}
      pagination={{
        clickable: true,
        el: `#${id}`,
      }}
      modules={[Pagination]}
      slidesPerView={slidesPerView}
      autoHeight={autoHeight}
    >
      {children}
    </Swiper>
    <div id={id} className={clsx(customPaginationClass || styles.ibSwiperPagination)} />
  </>
);

export const IBSwiperItem = SwiperSlide;
